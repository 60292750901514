<template lang="pug">
Container
	UiBreadcrumbs
	h1(class='Uppercase') {{ header }}
	div(class='Service', :class='{ NoAdditionalInfo: !serviceState.infoBlock }')
		div(class='Preview')
			UiImage(:image='serviceState.preview', width='500px', lazy)
		div(class='Price')
			div(class='Price') Стоимость: {{ serviceState.price > 0 ? $f.formatPrice(serviceState.price) : 'Бесплатно' }}
			div(class='Credit', v-if='serviceState.credit') Рассрочка: {{ $f.formatPrice(serviceState.price / serviceState.creditPeriod) }} x {{ serviceState.creditPeriod }} {{ $f.pluralize(serviceState.creditPeriod, ['платёж', 'платежа', 'платежей']) }}
			div(class='Button')
				UiButton(
					:size='greaterThan("tablet") ? "xl" : "md"',
					variant='primary',
					rounded,
					@click='getServiceModal'
				) Оформить
				UiButton(
					v-if='contacts?.whatsapp',
					:href='contacts.whatsapp',
					target='_blank',
					:size='greaterThan("tablet") ? "xl" : "md"',
					variant='whatsapp',
					iconLeft='social/whatsapp-inline',
					iconLeftSize='md',
					rounded
				) Whatsapp
		UiProse(v-if='serviceState.infoBlock', class='AdditionalInfo', :html='serviceState.infoBlock', awesome)

		UiProse(
			v-if='serviceState.fullDescription',
			class='FullDescription',
			:html='serviceState.fullDescription',
			awesome
		)

Container
	UnitChoose

Container
	UnitConsultation(type='employees')

Container
	UnitReviews

UiSubscribe
</template>

<script lang="ts" setup>
/**
 * Define props
 */
const { alias } = defineProps<{
	alias: string;
}>();

/**
 * Define hooks
 */
const { header } = useMeta();
const { greaterThan } = usePlatform();
const { serviceState, fetchService } = useService(alias);
const { contacts, fetchContacts } = useContacts();
const { modalState, modalShow } = useModal();

/**
 * Get service modal
 */
const getServiceModal = () => {
	if (!serviceState.value.form) return;

	modalState.value = {
		header: serviceState.value.form.header,
		description: serviceState.value.form.description,
		source: serviceState.value.form.source,
		subject: serviceState.value.form.subject,
		form: serviceState.value.form.fields,
		target: serviceState.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchService();
await fetchContacts();
</script>

<style lang="less" scoped>
.Service {
	.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; center);
	& > .Preview {
		max-height: 32rem;
		.grid(1 span);
		.relative(1; hidden);
		.box(flex; 100%; auto; none; center; center; center; nowrap row);
		.border-radius(@BorderRadiusMedium);
		& > img {
			object-fit: cover;
			.box(block; 100%; auto);
		}
	}
	& > .Price {
		padding: 1.5rem;
		.grid(1 span);
		.box(grid; 100%; auto; @ColorWhite; 0.5rem; auto; auto; center; start);
		.border-radius(@BorderRadiusMedium);
		& > .Price {
			.grid(1 span);
			.text(@ColorBase; 1.4em 1.125rem @semibold);
		}
		& > .Credit {
			.grid(1 span);
			.text(@ColorBase; 1.4em 0.875rem @medium);
		}
		& > .Button {
			margin: 1rem 0 0 0;
			.grid(1 span);
			.box(grid; auto; auto; @ColorWhite; 1rem; auto; auto; stretch; start; column);
		}
	}
	& > .AdditionalInfo {
		padding: 1.5rem;
		.grid(1 span);
		.box(block; 100%; auto; fade(@ColorBase, 5%));
		.border-radius(@BorderRadiusMedium);
	}
	& > .FullDescription {
		padding: 1.5rem;
		.grid(1 span);
		.box(block; 100%; auto; @ColorWhite);
		.border-radius(@BorderRadiusMedium);
	}

	&.NoAdditionalInfo {
		& > .FullDescription {
			.grid(1 span);
		}
	}

	@media all and (min-width: @tablet) and (max-width: (@laptop - 1px)) {
		.box(grid; 100%; auto; none; 2rem; auto; 1fr 1fr; start; center);
		& > .Preview {
			max-height: 100%;
			.grid(1 span; 2 span);
		}
		& > .Price {
			.grid(1 span);
		}
		& > .AdditionalInfo {
			.grid(1 span);
		}
		& > .FullDescription {
			.grid(1 span; 2 span);
		}

		&.NoAdditionalInfo {
			& > .Price {
				.grid(1 span; 2 span);
			}
			& > .FullDescription {
				.grid(1 span; 2 span);
			}
		}
	}

	@media all and (min-width: @laptop) {
		padding: 2rem 0 0 0;
		.box(grid; 100%; auto; none; 2rem; auto; 1fr 1.5fr; start; center);
		& > .Preview {
			.grid(1 2; 1 2);
		}
		& > .Price {
			padding: 2rem;
			.grid(2 3; 1 2);
		}
		& > .AdditionalInfo {
			padding: 2rem;
			.grid(3 4; 1 2);
		}
		& > .FullDescription {
			padding: 2rem;
			.grid(1 4; 2 3);
		}

		&.NoAdditionalInfo {
			& > .FullDescription {
				.grid(1 3; 2 3);
			}
		}
	}

	@media all and (min-width: @desktop) {
		& > .Price {
			& > .Price {
				.grid(1 span);
				.text(@ColorBase; 1.4em 1.5rem @semibold);
			}
			& > .Credit {
				.grid(1 span);
				.text(@ColorBase; 1.4em 1.125rem @regular);
			}
		}
		& > .FullDescription {
			padding: 5rem;
		}
	}
}
</style>
