<template lang="pug">
Container(v-if='greaterThan("tablet")')
	h1 {{ header }}

Container(v-for='group in servicesGroupState', :key='group.id')
	SliderServices(:groups='[group]', single)
		template(#header)
			h2 {{ group.name }}

Container
	UnitConsultation(type='employees')

UiSubscribe
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { header } = useMeta();
const { greaterThan } = usePlatform();
const { servicesGroupState, fetchServicesGroup } = useServices();

/**
 * Define fetch
 */
await fetchServicesGroup();
</script>
